export enum EventType {
  AppStarted = 'App Started',
  CreateMessage = 'Create Message',
  EditMessage = 'Edit Message',
  DeleteMessage = 'Delete Message',
}

export namespace AppEvents {
  export type AppStarted = {
    type: EventType.AppStarted
    params: {
      location: string
    }
  }

  export type CreateMessage = {
    type: EventType.CreateMessage
    params: {
      mode: 'dashboard' | 'quick'
      banner: boolean
    }
  }

  export type EditMessage = {
    type: EventType.EditMessage
  }

  export type DeleteMessage = {
    type: EventType.DeleteMessage
  }
}

export type AppEvent =
  | AppEvents.AppStarted
  | AppEvents.CreateMessage
  | AppEvents.EditMessage
  | AppEvents.DeleteMessage

export interface IAppEvent {
  type: EventType
  params?: Record<string, any>
}

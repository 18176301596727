import { AppEvent, IAppEvent } from './events'

export * from './events'

type SDK = typeof import('posthog-js')
const POSTHOG_PROJECT_ID = 'phc_ntUzVpf37C8fMGEvVLnwKZ7pgeV7l80wcCw7Ga6x8Sz'

interface IInitParams {
  user?: string
  app_name: string
  app_version: string
}

let sdkPromise: Promise<SDK> | undefined = undefined

export const init = ({ user, app_name, app_version }: IInitParams) => {
  if (!POSTHOG_PROJECT_ID) {
    return
  }
  sdkPromise = import('posthog-js').then((sdk) => {
    sdk.default.init(POSTHOG_PROJECT_ID, {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only',
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false,
    })

    sdk.default.register({
      $app_version: app_version,
      app_name,
    })

    if (user) {
      setUserId(user)
    }

    return sdk
  })
  // Uncomment to track app start. Don't forget to map AppStarted to $pageview
  // .then((sdk) => {
  //   trackEvent({
  //     type: EventType.AppStarted,
  //     params: {
  //       location: window.location.href,
  //     },
  //   })
  //   return sdk
  // })
}

export const trackEvent = (event: AppEvent) => {
  const evt = event as IAppEvent
  sdkPromise?.then((sdk) => {
    sdk.default.capture(evt.type, evt.params)
    return sdk
  })
}

export const setUserId = (email: string | undefined) => {
  sdkPromise?.then((sdk) => {
    sdk.default.identify(email)
    return sdk
  })
}

import { useEffect, useRef } from 'react'
import * as Analytics from 'summer-analytics-client'
import { APP_NAME, VERSION } from '@/constants'
import { useSession } from 'next-auth/react'

const useEventTracking = (email: string | undefined) => {
  const firstRun = useRef(true)
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    if (firstRun.current) {
      Analytics.init({
        user: email,
        app_name: APP_NAME,
        app_version: VERSION,
      })
      firstRun.current = false
    } else {
      Analytics.setUserId(email)
    }
  }, [email])
}

export const Tracker = () => {
  const isClient = typeof window !== 'undefined'
  if (!isClient) {
    return null
  }
  const session = useSession()
  const email = session?.data?.user?.email ?? undefined
  useEventTracking(email)
  return null
}
